import React, { Component } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/layout";
import { hideVisually } from "polished";
import shuffle from "../utils/shuffle";

export default class Template extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bannerImage: null
    };
  }

  componentDidMount() {
    const { data } = this.props;
    const { homepageImages } = data;
    const { edges: images } = homepageImages;
    const { node: randomImage } = shuffle(images)[0];
    this.setState({ bannerImage: randomImage });
  }

  render() {
    const { data } = this.props;
    const { markdownRemark } = data;
    const { frontmatter, html } = markdownRemark;
    return (
      <Layout
        bannerImage={
          this.state.bannerImage && this.state.bannerImage.childImageSharp.fluid
        }
        metaDescription={frontmatter.metaDescription}
      >
        <HiddenPageTitle>{frontmatter.title}</HiddenPageTitle>
        <Content dangerouslySetInnerHTML={{ __html: html }} />
      </Layout>
    );
  }
}

const HiddenPageTitle = styled.h1`
  ${hideVisually}
`;

const Content = styled.div`
  max-width: 60ch;
  text-shadow: 1px 1px 2px #000, 0 0 1em ${({ theme }) => theme.palette.dark},
    0 0 0.2em ${({ theme }) => theme.palette.dark};
`;

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        metaDescription
      }
    }
    homepageImages: allFile(
      filter: {
        extension: { eq: "jpg" }
        sourceInstanceName: { eq: "cmsHomepageImages" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
